<template>
	<div class="h-100 dis-flex-com ft-sz-14 po-r">
		<div class="txt-c">
			<div class="pd-x-15 pd-t-10 pd-b-10 ft-w-4 ft-sz-18">
				{{info.title}}
			</div>
			<div class="pd-x-15 pd-b-10 ft-sz-12 bd-bott ft-co-99">
				{{util.formatDate('cn',info.updatetime)}}
			</div>
		</div>
		<div class="flx-1 pd-x-15 pd-t-10 pd-b-10">
			<div v-html="info.content"></div>
		</div>
		<div class="dis-flex-com">
			<div class="buttonPa">
				<button class="pd-y-10 w-100 ft-co-ff" type="button" @click="pushGo()">返回</button>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.buttonPa {
		margin-top: auto;
		padding: 10px 30px;
	}

	button {
		border: none;
		color: #FFFFFF;
		border-radius: 30px;
		background: -webkit-linear-gradient(#f7514d, #fd704d);
	}

	button:focus {
		outline: none !important;
	}
</style>
<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import wx from 'weixin-js-sdk'
	import {
		Toast,
		Dialog
	} from 'vant';
	var {
		infomanager,
		adminGeneral
	} = http
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				util: util,
				info: {},
				url: 1
			}
		},
		created() {},
		mounted() {
			this.detailInfo();
			this.fenXiang();
		},
		activated() {},
		methods: {
			detailInfo() {
				var s = this;
				s.loading = true;
				var condition = {};
				infomanager.detailInfo.http({
					"companyid": s.$route.params.companyid,
					"productid": s.$route.params.productid,
					"infoid": s.$route.params.id
				}).then(res => {
					if (res.getret === 0) {
						let content = res.info.content;
						const regex = new RegExp('<img', 'gi')
						res.info.content = content.replace(regex, `<img style="width: 100%; height: auto"`);
						s.info = res.info;
					} else {
						Toast(res.msg);
					}
				})
			},
			pushGo() { /*返回*/
				if (this.url == 1) {
					this.$router.push('/');
				} else {
					this.$router.go(-1);
				}
			},
			fenXiang() {
				var s = this;
				console.log(location.href.split('#')[0], location.href.replace(/[&0]$/, s.$route.params.id))
				adminGeneral.jssdkSignature.http({
					url: location.href.split('#')[0]
				}).then(res => {
					if (res.getret == 0) {
						wx.config({
							debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
							appId: res.data.appId, // 必填，公众号的唯一标识
							timestamp: res.data.timestamp + '', // 必填，生成签名的时间戳
							nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
							signature: res.data.signature, // 必填，签名
							jsApiList: ['updateAppMessageShareData', 'publishTimelineShareData', 'onMenuShareAppMessage',
								'onMenuShareTimeline', 'getLocation'
							] // 必填，需要使用的JS接口列表
						});
						wx.checkJsApi({
							jsApiList: ['updateAppMessageShareData', 'publishTimelineShareData', 'onMenuShareAppMessage',
								'onMenuShareTimeline', 'getLocation'
							], // 需要检测的JS接口列表，所有JS接口列表见附录2,
							success: function(res) {
								console.log('wx.checkJsApi-----', res)
							}
						});
						wx.error(function(res) {
							console.log('wx.config-----', res)
						});
						wx.ready(function() { //需在用户可能点击分享按钮前就先调用
							wx.updateAppMessageShareData({
								title: s.info.title, // 分享标题
								desc: s.info.title, // 分享描述
								link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
								// imgUrl: util.pageHost+'images/banner4.jpg', // 分享图标
								success: function() {

								}
							})
							wx.publishTimelineShareData({
								title: s.info.title, // 分享标题
								link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
								// imgUrl: util.pageHost+'images/banner4.jpg', // 分享图标
								success: function() {
									// 设置成功
								}
							})
						});
					}
				})
			}
		},
		beforeRouteEnter(to, from, next) {
			// 在导航完成前获取数据
			// 判断是直接进入 还是从其他页面进入
			next(vm => {
				// console.log(from.path) 
				if (from.path == '/') {
					// 从根目录直接进入页面 自定义hash值
					vm.url = 1;
				} else {
					vm.url = 2;
				}
			});
		},
	}
</script>
